import React from "react";
import { FormHelperText } from '@material-ui/core';

const Alerta = ({ msg, ...rest }) => {
  return (
    <FormHelperText error {...rest}>
    {msg}
    </FormHelperText>
  );
};
export default Alerta;

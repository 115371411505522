import caronecloud from "./clients/caronecloud";
import carmenmotors from "./clients/carmenmotors";
import unitedauto from "./clients/unitedauto";
import demo from "./clients/demo";
import development from "./clients/development";
import surman from "./clients/surman";

const removeKeywords = [
  "www.",
  "localhost.",
  ".io",
  ".com",
  "crm.",
  "https://",
  "http://",
  "local.",
  "staging.",
  ".warbox",
  ".netlify",
  ".app",
  "citas.",
];

const host = window.location.hostname.toLowerCase();

const siteName = removeKeywords.reduce((acc, keyword) => {
  return acc.replace(keyword, "");
}, host);

const SITE_SETTINGS = {
  caronecloud,
  carmenmotors,
  unitedauto,
  surman,
  demo,
  default: development,
};

export default SITE_SETTINGS[siteName]
  ? SITE_SETTINGS[siteName]
  : SITE_SETTINGS.default;

import React, { useEffect } from "react";
import { Container, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import TicketCard from "../../components/ticketCard/TicketCard";
import useAppointment from "../../hooks/useAppointment";
import useStore from "../../hooks/useStore";
import moment from "moment";
import { useParams } from 'react-router';
import { socket } from '../../service/socket';
import Navbar from "../../layout/navbar";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const TicketView = () => {
  const classes = useStyles();
  const { appointments, getAppointments, resetAppointments} = useAppointment();
  const {store, getStore} = useStore();
  const {id} = useParams();

  const handleStore = () =>{
    getStore(id);
    console.log(store);
  }
  useEffect(() => {
    handleStore();
    //eslint-disable-next-line
  }, [id]);


  

  useEffect(()=>{

    socket.emit('leaveRoom', `calendar-tickets-${id}`);
    socket.emit('connectRoom', `calendar-tickets-${id}`);
    socket.on('setTicketsCalendar', data => {
      resetAppointments(data)
    });
    //eslint-disable-next-line
  },[])

  useEffect(() => {
    handleAppointments();
    //eslint-disable-next-line
  }, [id]);

  const handleAppointments = ()=>{
      getAppointments(
        `store[in]=${id}&startDate[gte]=${moment()
          .startOf("day")
          .format()}&startDate[lt]=${moment().endOf("day").format()}`
      );
      
  };

  return (
    <Container fullWidth className={classes.class}>
      
      <Grid item xs={12}>
        <Navbar store={store} />
      </Grid>
      <Grid item xs={12}>
        {appointments &&
          appointments.map((apt, i) => (
            <TicketCard data={apt} i={i} store={store} key={apt._id} />
          ))}
      </Grid>
    </Container>
  );
};

export default TicketView;
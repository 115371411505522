export default {
  siteName: "localhost",
  senderName: "Local Host",
  logo: "/static/warbox_logo.png",
  name: "Local Host",
  api: {
    production: "https://api.warbox.io/api/v1",
    staging: "https://staging.api.warbox.io/api/v1",
    development: "http://localhost:5001/api/v1",
  },
  client: "demo",
};

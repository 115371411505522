export default {
  siteName: "surman",
  senderName: "War Box - Grupo Surman",
  logo: "/static/warbox_logo.png",
  name: "War Box",
  api: {
    production: "https://api.warbox.io/api/v1",
    staging: "https://staging.api.warbox.io/api/v1",
    development: "http://localhost:5001/api/v1",
  },
  client: "surman",
};

import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  TextField,
  Typography,
} from '@material-ui/core';
import useAuth from '../../hooks/useAuth';
import sortNames from '../../utils/sortNames';
import useStore from '../../hooks/useStore';
import { useHistory } from 'react-router-dom';
import { isRockstar, isSuper, isGeneralManager, isSalesManager} from '../../utils/AuthRoles';

const styles = {
  root: {},
  cardContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    width: 410,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  cardContent: {
    padding: 30,
    display: 'flex',
    flexDirection: 'column',
  },
  currentMethodIcon: {
    height: 60,
    '& > img': {
      width: 'auto',
      maxHeight: '100%'
    }
  },
  rightText: {
    float: 'right'
  },
  header: {
    fontSize: 32,
    fontWeight: 100,
    letterSpacing: 3,
    // color: 'white'
  },
  subheader: {
    fontSize: 20,
    fontWeight: 100,
    letterSpacing: 3,
    textTransform: 'capitalize'
    // color: 'white'
  }
}

const Menu = () => {

  const [values, setValues] = useState({store: '' })
  const { user } = useAuth()
  const [agencias, setAgencias] = useState([])
  const { stores, getStores, getStoresByGroup} = useStore()
  const history = useHistory()
  const handleRedirect = () => {
    history.push(`/calendar/${values.store}`)
  }

  useEffect(()=>{
    if(user && user.tier){
        let val = {store: ''};
        if(isRockstar(user.tier._id)){
          getStores()
        }else if(isSuper(user.tier._id) || isGeneralManager(user.tier._id) || isSalesManager(user.tier._id)){
          getStoresByGroup(user.group._id)
        }else{
          if (user.stores) {
            let nameSS = user.stores
            .map(store => {
                return {
                  _id: store._id,
                  name: `${store.make.name} ${store.name}`.replace('-', ' ')
                };
              })
              .sort((a, b) =>sortNames(a, b));

            val = { ...val, store: user.stores[0]._id }
            setAgencias(nameSS)
          }
        }
        setValues(val)
    }
    //eslint-disable-next-line
  }, [user])

  useEffect(()=>{
    if(stores && stores[0]) {
        let sortedStores = stores
        .map(store => {
          return {
            _id: store._id,
            name: `${store.make.name} ${store.name}`.replace('-', ' ')
          };
        })
        .sort((a, b) =>sortNames(a, b));
        setAgencias(sortedStores)
        setValues({ ...values, store: stores[0]._id })
    }
    //eslint-disable-next-line
  },[stores])

  return (
    <Box style={styles.root}>
      <Container style={styles.cardContainer} >
        <Box display='flex' justifyContent='center'>
          <Typography color='textPrimary' style={styles.header}>Bienvenido</Typography>
        </Box>
        <Box display='flex' justifyContent='center' pb={3}>
          <Typography color='textPrimary' style={styles.subheader}>{user && user.name}</Typography>
        </Box>
        <Card elevation={0}>
          <CardContent style={styles.cardContent}>
            <Box mt={2}>
              <TextField
                name='store'
                label='Agencia'
                variant="outlined"
                onChange={(e)=>setValues({ ...values, [e.target.name]: e.target.value })}
                fullWidth
                select
                SelectProps={{native:true}}
                value={values.store}
                InputLabelProps={{shrink: true}}
                inputProps={{style: { textTransform: 'capitalize'}}}
              >
                {
                    agencias.map(item => <option value={item._id} key={item._id}>{item.name}</option>)
                }
              </TextField>
            </Box>
            <Box mt={2}>
              <Button variant='contained' color='primary' fullWidth sx={{height: 55}} onClick={handleRedirect}>
                Ingresar 
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default Menu;

import React from "react";
import { Box, Button, Typography } from "@material-ui/core";
import useAuth from "../../hooks/useAuth";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

const useStyles = makeStyles({
  navbarStyle: {
    background: "white",
  },
  MainNav: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  menu: {
    color: "black",
  },
});

const Navbar = ({ store }) => {
  const { user, logout, isAuthenticated, loadUser } = useAuth();
  const history = useHistory();

  const handleLogout = async () => {
    await logout();
    history.push("/login");
  };

  React.useEffect(() => {
    if (!isAuthenticated) {
      loadUser();
    }
    //eslint-disable-next-line
  }, []);

  const classes = useStyles();

  const renderMakeName = () => {
    if (!store?.image && store?.make?.name) return store?.make?.name;
    else return "";
  };

  const renderStoreName = () => store?.name || "";

  return (
    <>
      {user ? (
        <Box
          className={classes.MainNav}
          style={{ padding: "15px", alignItems: "center" }}
        >
          <Box
            style={{
              width: "100%",
              alignItems: "center",
              display: "flex",
              textAlign: "center",
              justifyContent: "flex-start",
            }}
          >
            {store?.image ? (
              <img
                src={store.image}
                alt="imagenAgencia"
                style={{ width: 90, height: "auto", marginRight: 20 }}
              />
            ) : null}
            <Typography
              variant="h4"
              style={{ textTransform: "uppercase", fontWeight: "bold" }}
            >
              {`${renderMakeName()} ${renderStoreName()}`}
            </Typography>
          </Box>
          <Button
            variant="contained"
            style={{
              backgroundColor: store?.make?.color
                ? store.make.color
                : "#8f9192",
              color: "#FFFFFF",
              height: 50,
              minWidth: "15ch",
            }}
            onClick={handleLogout}
            startIcon={
              <ExitToAppIcon color={"#FFFFFF"} style={{ fontSize: "33px" }} />
            }
          >
            <Typography variant="body1">Salir</Typography>
          </Button>
        </Box>
      ) : (
        ""
      )}
    </>
  );
};

export default Navbar;

import React, { useReducer } from "react";
import AppointmentContext from "./appointmentContext";
import AppointmentReducer from "./appointmentReducer";
import api from "../../api/api";
import {
  GET_APPOINTMENTS,
  GET_APPOINTMENT,
  UPDATE_APPOINTMENT,
  SET_APPOINTMENTS,
  SET_ERROR,
  CLEAR_STATE,
  SET_LOADING,
} from "../types";
import { HEADERS } from "../../constants/headers";

const AppointmentState = (props) => {
  const initialState = {
    appointments: [],
    appointment: {},
    loading: false,
    error: null,
  };

  const [state, dispatch] = useReducer(AppointmentReducer, initialState);

  //Get Appointments
  const getAppointments = async (query) => {
    clearState();
    setLoading();
    try {
      const res = await api.get(`appointments/date?${query}`, HEADERS());
      dispatch({ type: GET_APPOINTMENTS, payload: res.data.data });
    } catch (err) {
      console.log("0", err);
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Get Appointment
  const getAppointment = async (appointmentId) => {
    setLoading();
    try {
      const res = await api.get(`/appointments/${appointmentId}`, HEADERS());
      dispatch({ type: GET_APPOINTMENT, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Get Appointment
  const resetAppointments = async (apps) =>
    dispatch({ type: SET_APPOINTMENTS, payload: apps });

  //Update Appointment
  const visitLead = async (appointmentId) => {
    setLoading();
    try {
      await api.post(`/leads/visit/${appointmentId}`, {}, HEADERS());
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Update Appointment
  const updateAppointment = async (appointment, appointmentId) => {
    setLoading();
    try {
      const res = await api.put(
        `/appointments/${appointmentId}`,
        { ...appointment },
        HEADERS()
      );
      dispatch({ type: UPDATE_APPOINTMENT, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Clear State
  const clearState = () => dispatch({ type: CLEAR_STATE });

  //Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });

  return (
    <AppointmentContext.Provider
      value={{
        loading: state.loading,
        appointments: state.appointments,
        appointment: state.appointment,
        error: state.error,
        getAppointments,
        getAppointment,
        resetAppointments,
        updateAppointment,
        clearState,
        setLoading,
        visitLead,
      }}
    >
      {props.children}
    </AppointmentContext.Provider>
  );
};

export default AppointmentState;

// General
export const CLEAR_STATE = 'CLEAR_STATE'
export const SET_ERROR = 'SET_ERROR'
export const SET_LOADING = 'SET_LOADING'

// Auth
export const USER_LOADED = 'USER_LOADED'
export const AUTH_ERROR = 'AUTH_ERROR'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGOUT = 'LOGOUT'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const UPDATE_PROFILE = 'UPDATE_PROFILE'

// Appointments
export const GET_APPOINTMENTS = 'GET_APPOINTMENTS'
export const GET_APPOINTMENT = 'GET_APPOINTMENT'
export const UPDATE_APPOINTMENT = 'UPDATE_APPOINTMENT'
export const SET_APPOINTMENTS = 'SET_APPOINTMENTS'

export const GET_STORE = 'GET_STORE'
export const GET_STORES = 'GET_STORES'
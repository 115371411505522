import React, { useReducer } from "react";
import api from "../../api/api";
import StoreContext from "./storeContext";
import StoreReducer from "./storeReducer";
import {
  SET_ERROR,
  CLEAR_STATE,
  SET_LOADING,
  GET_STORE,
  GET_STORES,
} from "../types";
import { HEADERS } from "../../constants/headers";

const StoreState = (props) => {
  const initialState = {
    stores: [],
    store: {},
    loading: false,
    error: null,
  };
  const [state, dispatch] = useReducer(StoreReducer, initialState);

  //Get Stores
  const getStores = async () => {
    clearState();
    setLoading();
    try {
      const res = await api.get(`/stores`, HEADERS());
      dispatch({ type: GET_STORES, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.erro });
    }
  };
  //Get Stores By Group
  const getStoresByGroup = async (groupId) => {
    setLoading();
    try {
      const res = await api.get(`/groups/${groupId}/stores`, HEADERS());
      dispatch({ type: GET_STORES, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const getStore = async (id) => {
    setLoading();
    try {
      const res = await api.get(`/stores/${id}`, HEADERS());
      dispatch({ type: GET_STORE, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Clear State
  const clearState = () => dispatch({ type: CLEAR_STATE });

  //Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });

  return (
    <StoreContext.Provider
      value={{
        loading: state.loading,
        stores: state.stores,
        store: state.store,
        error: state.error,
        clearState,
        setLoading,
        getStore,
        getStores,
        getStoresByGroup,
      }}
    >
      {props.children}
    </StoreContext.Provider>
  );
};
export default StoreState;

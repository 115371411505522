export default {
  siteName: "caronecloud",
  senderName: "Car One Cloud",
  logo: "/static/logo.png",
  name: "Car One Cloud",
  api: {
    production: "https://api.warbox.io/api/v1",
    staging: "https://staging.api.warbox.io/api/v1",
    development: "http://localhost:5000/api/v1",
  },
  client: "carone",
};

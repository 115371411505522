import React, { useReducer } from "react";
import AuthContext from "./authContext";
import AuthReducer from "./authReducer";
import api from "../../api/api";
import {
  USER_LOADED,
  LOGIN_SUCCESS,
  LOGOUT,
  LOGIN_FAIL,
  SET_LOADING,
  CLEAR_STATE,
} from "../types";
import { HEADERS } from "../../constants/headers";

const AuthState = (props) => {
  const initialState = {
    token: localStorage.getItem("token"),
    isAuthenticated: false,
    user: {},
    loading: false,
    error: null,
  };

  const [state, dispatch] = useReducer(AuthReducer, initialState);

  const clearState = () => dispatch({ type: CLEAR_STATE });

  //Set Current User
  const loadUser = async () => {
    try {
      const res = await api.get(`/auth/me`, HEADERS());
      dispatch({
        type: USER_LOADED,
        payload: res.data.data,
      });
    } catch (err) {
      dispatch({ type: LOGIN_FAIL, payload: err.response.data });
    }
  };

  //Login User
  const login = async (values) => {
    setLoading();

    try {
      const res = await api.post("/auth/login", values, HEADERS());
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });

      loadUser();
    } catch (err) {
      dispatch({
        type: LOGIN_FAIL,
        payload: err.response.data,
      });
    }
  };

  // Logout
  const logout = () => dispatch({ type: LOGOUT });

  const setLoading = () => dispatch({ type: SET_LOADING });

  return (
    <AuthContext.Provider
      value={{
        token: state.token,
        loading: state.loading,
        isAuthenticated: state.isAuthenticated,
        user: state.user,
        error: state.error,
        login,
        loadUser,
        logout,
        clearState,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;

import React, { useState } from 'react';
import { Button, DialogActions, DialogContent, TextField, Box, makeStyles, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { useParams } from 'react-router-dom';
import useAppointment from '../hooks/useAppointment';
import { useSnackbar } from 'notistack';
import Alerta from './Alert';

const useStyles = makeStyles(theme => ({
    input: {
      height: 100,
      margin: 5,
      fontSize: 48,
      textAlign: 'center',
    },
    textfield: {
        margin: 10
    }
}));

const VerifyNumber = ({ open, setOpen, setDisableButton, value, message }) => {

    const classes = useStyles();

    const [err, setErr] = useState(null)
    const [current, setCurrent] = useState(0)
    const [values, setValues] = useState(['', '', '', ''])
    const { id } = useParams();
    const { visitLead } = useAppointment();
    const { enqueueSnackbar } = useSnackbar();  
    
    const validate = async() => {
        if(`${values[0]}${values[1]}${values[2]}${values[3]}` === value) {
            setErr(null)
            setOpen(false)
            await  visitLead(id);
            setValues(['', '', '', ''])

            setDisableButton(true)
            enqueueSnackbar("Visita Exitosa", {
              variant: 'success'
            });
        }
        else {
            setValues(['', '', '', ''])
            setErr('Numero Incorrecto')
        }
    }

    const justNumbers = (value) => {
        let regex = new RegExp(/^[0-9]+$/);
        return regex.test(value);
    }

    return (
        <Dialog onClose={()=>{setOpen(false)}} aria-labelledby="simple-dialog-title" open={open}>
        <DialogContent>
            <center>
                <Typography variant='h5' style={{marginBottom: '1em'}}>{message}</Typography>
            </center>
            <Box display='flex' justifyContent='row'>
                <TextField
                    variant='outlined'
                    onChange={(e)=>{
                        if(e.target.value === '' || justNumbers(e.target.value)) {
                            let aux = [...values];
                            aux[0] = e.target.value;
                            setValues(aux)
                        }
                        if(e.target.value !== '' && justNumbers(e.target.value)){
                            setCurrent(1)
                        }
                    }}
                    inputProps={{className: classes.input, maxLength: 1}}
                    className={classes.textfield}
                    inputRef={input => input && current === 0 && input.focus()}
                    onClick={()=>setCurrent(0)}
                    value={values[0]}
                />
                <TextField
                    variant='outlined'
                    onChange={(e)=>{
                        if(e.target.value === '' || justNumbers(e.target.value)) {
                            let aux = [...values];
                            aux[1] = e.target.value;
                            setValues(aux)
                        }
                        if(e.target.value !== '' && justNumbers(e.target.value)){
                            setCurrent(2)
                        }
                    }}
                    inputProps={{className: classes.input, maxLength: 1}}
                    className={classes.textfield}
                    inputRef={input => input && current === 1 && input.focus()}
                    onClick={()=>setCurrent(1)}
                    value={values[1]}
                />
                <TextField
                    variant='outlined'
                    onChange={(e)=>{
                        if(e.target.value === '' || justNumbers(e.target.value)) {
                            let aux = [...values];
                            aux[2] = e.target.value;
                            setValues(aux)
                        }
                        if(e.target.value !== '' && justNumbers(e.target.value)){
                            setCurrent(3)
                        }
                    }}
                    inputProps={{className: classes.input, maxLength: 1}}
                    className={classes.textfield}
                    inputRef={input => input && current === 2 && input.focus()}
                    onClick={()=>setCurrent(2)}
                    value={values[2]}
                />
                <TextField
                    variant='outlined'
                    onChange={(e)=>{
                        if(e.target.value === '' || justNumbers(e.target.value)) {
                            let aux = [...values];
                            aux[3] = e.target.value;
                            setValues(aux)
                        }
                        if(e.target.value !== '' && justNumbers(e.target.value)){
                            setCurrent(-1)
                        }
                    }}
                    inputProps={{className: classes.input, maxLength: 1}}
                    className={classes.textfield}
                    inputRef={input => input && current === 3 && input.focus()}
                    onClick={()=>setCurrent(3)}
                    value={values[3]}
                />
            </Box>
            {
                err &&
                <Box display='flex' justifyContent='center'>
                    <Alerta severity="error" msg={err} style={{fontSize: 24}}/>
                </Box> 
            }
            <DialogActions>
                <Button onClick={() => setOpen(false)} color="error">
                    Cancelar
                </Button>
                <Button onClick={() => validate()} color="primary">
                    Confirmar
                </Button>
        </DialogActions>
        </DialogContent>
        </Dialog>
    );
};

export default VerifyNumber;

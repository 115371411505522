import axios from "axios";
import SITE_SETTINGS from "../constants/whiteLabel";
const baseURL =
  SITE_SETTINGS.api[process.env.REACT_APP_NODE_ENV || "development"];

export default axios.create({
  baseURL,
});

export { baseURL };

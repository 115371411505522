import { 
    GET_STORE,
    SET_ERROR,
    CLEAR_STATE,
    SET_LOADING,
    GET_STORES,
   } from '../types';

   const reducer = (state, action) => {
    switch (action.type) {
        case GET_STORE:
            return {
                ...state,
                store: action.payload,
                loading: false, 
                error: null
            };
        case GET_STORES:
            return {
                ...state,
                stores: action.payload,
                count: action.count,
                loading: false
            };
        case SET_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        case CLEAR_STATE:
            return {
                store: {},
                loading: false,
                error: null
            }
        case SET_LOADING:
            return {
                ...state,
                loading: true
            }
        default:
            return state;
    }

   };

   export default reducer;

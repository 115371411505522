import React, { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  Button,
} from '@material-ui/core';
import { Eye, EyeOff } from 'react-feather';
import useAuth from '../../hooks/useAuth';
import AlertP from '../../components/Alert';

const styles = {
  root: {},
  cardContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    width: 410,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  cardContent: {
    padding: 30,
    display: 'flex',
    flexDirection: 'column',
  },
  currentMethodIcon: {
    height: 60,
    '& > img': {
      width: 'auto',
      maxHeight: '100%'
    }
  },
  rightText: {
    float: 'right'
  },
  header: {
    fontSize: 32,
    fontWeight: 100,
    letterSpacing: 3,
    // color: 'white'
  },
  subheader: {
    fontSize: 20,
    fontWeight: 100,
    letterSpacing: 3,
    // color: 'white'
  }
}

const JWTLogin = () => {

  const [values, setValues] = useState({ email: '', password: '' })
  const [show, setShow] = useState('password')

  const { login, error } = useAuth()

  const handleLogin = () => {
    login(values)
  }

  const handleClickShowPassword = () => {
    if(show === 'password'){
      setShow('text')
    }else{
      setShow('password')
    }
  }

  return (
    <Box style={styles.root}>
      <Container style={styles.cardContainer} >
        <Box display='flex' justifyContent='center'>
          <Typography color='textPrimary' style={styles.header}>Bienvenido</Typography>
        </Box>
        <Box display='flex' justifyContent='center' pb={3}>
          <Typography color='textPrimary' style={styles.subheader}>Credenciales</Typography>
        </Box>
        <Card elevation={0}>
          <Box alignItems="center" display="flex" justifyContent="center" p={2}>
            <Typography color="textPrimary" variant="h5">
              Ingresar
            </Typography>
          </Box>
          <Divider />
          <CardContent style={styles.cardContent}>
            <Box>
              <TextField
                name='email'
                label='Correo'
                variant="outlined"
                onChange={(e)=>setValues({ ...values, [e.target.name]: e.target.value })}
                fullWidth
              />
            </Box>
            <Box mt={2}>
              <TextField
                name='password'
                label='Contraseña'
                variant="outlined"
                onChange={(e)=>setValues({ ...values, [e.target.name]: e.target.value })}
                fullWidth
                type={show}
                InputProps={{
                  endAdornment: 
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {show === 'password' ? <Eye/> : <EyeOff/>}
                    </IconButton>
                  </InputAdornment>
                }}
              />
            </Box>
            {
              error && 
              <Box mt={2}>
                <AlertP severity='error' msg={error.error}/>
              </Box>
            }
            <Box mt={2}>
              <Button variant='contained' color='primary' fullWidth sx={{height: 55}} onClick={handleLogin}>
                Ingresar 
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default JWTLogin;
